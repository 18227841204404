import React,{useState,useEffect} from 'react';
import { Grid, Typography } from '@mui/material';
import { Controller } from "react-hook-form";
import { TextField } from "@material-ui/core";
import { API } from '../../const';
import axios from 'axios';

export default function ClientGetter(props) {
    const [client,setClient] = useState({
        id:0,
        name:''
    });
    async function fetchClientName(id){
        try {
            let res = await axios.post(API+"api/"+"client"+"/clientName",{id:id})
            setClient({
                ...client,name:res.data.name
            })
            props.setValue("client.name", res.data.name)
        } catch (error) {
            console.log(error)
            setClient({
                ...client,name:''
            })
        }
    }
    return (
        <Grid container direction='row' spacing={2}>
            <Grid item xs={6} >
                <p style={{magin:'0px',color:'#17a2b8'}} variant='body1'>رقم العميل</p>
                <Controller
                    control={props.control}
                    rules={props.registerObject}
                    name={"client.id"}
                    render={({ field }) => (
                        <>
                            <TextField
                                {...field}
                                onChange={async (e)=> {
                                    field.onChange(e.target.value);
                                    await fetchClientName(e.target.value);
                                }}
                                placeholder="رقم العميل"
                                color="primary"
                                type="number"
                                variant="outlined"
                                size= "small"
                                id={'variant' + "-basic"}
                                isRTL={true}
                            >

                            </TextField>
                        </>
                    )} />
            </Grid>
            <Grid item xs={6}>
            <p style={{magin:'0px',color:'#17a2b8'}} variant='body1'>اسم العميل</p>

                <Controller
                    disabled={true}
                    control={props.control}
                    rules={props.registerObject}
                    name={"client.name"}
                    render={({ field }) => (
                        <>
                            <TextField
                                {...field}
                                placeholder="اسم العميل"
                                color="primary"
                                type="text"
                                variant="outlined"
                                size= "small"
                                id={'variant' + "-basic"}
                                isRTL={true}
                                value={client.name || props.getValues("client.name")}
                            >
                            </TextField>
                        </>
                    )} />
            </Grid>
        </Grid>
    )
}