import { Typography,Grid } from '@mui/material';
import React from 'react'
import Layout from '../components/layout/layout';
export default function ErrorScreen({error}) {
    return (
        <Layout>
            <div style={{display:'flex',height:"70vh"}}>
            <Grid container  style={{textAlign:'center',margin:'auto'}} justifyContent={'center'} alignItems={'center'} alignContent={'center'}>
                <Grid item xs={12}><Typography variant="h3">We are sorry... something went wrong</Typography></Grid>
                <Grid item xs={12}><Typography variant="subtitle2">We cannot process your request at this moment, if it's not the first time you see this screen please contact Admin</Typography></Grid>
                <Grid item xs={12}><Typography variant="subtitle2">ERROR: {error.message}</Typography></Grid>
            </Grid>
            </div>
        </Layout>
    );
}