
const captains=(state={},action)=>{
    switch (action.type){
         case 'addCaptains':
            localStorage.setItem('captains', JSON.stringify(action.payload));
            return action.payload;
        default:
            return state;
    }
}
export default captains;