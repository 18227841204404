import { Typography } from '@mui/material';
import React from 'react'
import Layout from '../layout/layout';
import car from './car.gif'
export default function LoadingPage() {
    return (
        <Layout>
            <div style={{
                width: "100%",
                textAlign: "center",
                display: "flex",
                minHeight:"100vh",
                backgroundColor:"#fff"
            }}>
                <div style={{margin:'auto',textAlign:'center'}}>
                    <img   width="220px"  src={car} alt="loading..." />
                    <Typography variant='h6'>Loading . . .</Typography>
                    </div>
                </div>
        </Layout>
    )
}