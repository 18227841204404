const TimeChange=(state={
    type:localStorage.getItem('TimeChange'),
    diff:localStorage.getItem('TimeChange')==='false'?0:1
},action)=>{
    switch (action.type){
         case 'updateTimeChange':
            return  {type:action.payload,diff:action.payload==false?0:1};
            break;
        default:
            return state
    }
}
export default TimeChange;