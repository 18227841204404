import React, { useEffect, useState } from "react";
import { Navbar, Nav, NavDropdown, Badge } from "react-bootstrap/";
import config from "../../config";
import logo from "./logo.png";
import { GiExitDoor } from "react-icons/gi";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { deleteUser } from "../../actions/user";
import {updateForm} from "../../actions/form"
import entities from "../../components/Forms/formData";
import { styled } from '@mui/material/styles';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch, { SwitchProps } from '@mui/material/Switch';
import { updateTimeChange } from "../../actions/timeChange";
export default function AbstractHeader(props) {
    const history = useHistory();
    const {user,TimeChange} = useSelector((state) => state);
    console.log(TimeChange);
    const dispatch = useDispatch();
    const MaterialUISwitch = styled(Switch)(({ theme }) => ({
        width: 56,
        height: 28,
        padding: 7,
        '& .MuiSwitch-switchBase': {
          margin: 1,
          padding: 0,
          transform: 'translateX(6px)',
          '&.Mui-checked': {
            color: '#fff',
            transform: 'translateX(22px)',
            '& .MuiSwitch-thumb:before': {
              backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
                '#fff',
              )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
            },
            '& + .MuiSwitch-track': {
              opacity: 1,
              backgroundColor:'#aab4be',
            },
          },
        },
        '& .MuiSwitch-thumb': {
          backgroundColor: '#001e3c',
          width: 26,
          height: 26,
          '&:before': {
            content: "''",
            position: 'absolute',
            width: '100%',
            height: '100%',
            left: 0,
            top: 0,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 23 23"><path fill="${encodeURIComponent(
              '#fff',
            )}" d="M 20.41 12 l 1.3 -1.29 a 1 1 0 0 0 -1.42 -1.42 L 18.59 11 H 15.87 a 4.05 4.05 0 0 0 -0.42 -1 l 1.91 -1.92 h 2.42 a 1 1 0 0 0 0 -2 H 18 V 4.22 a 1 1 0 1 0 -2 0 V 6.64 L 14 8.55 a 4.05 4.05 0 0 0 -1 -0.42 V 5.41 l 1.71 -1.7 a 1 1 0 1 0 -1.42 -1.42 L 12 3.59 l -1.29 -1.3 A 1 1 0 0 0 9.29 3.71 L 11 5.41 V 8.13 a 4.05 4.05 0 0 0 -1 0.42 L 8.05 6.64 V 4.22 a 1 1 0 0 0 -2 0 V 6.05 H 4.22 a 1 1 0 0 0 0 2 H 6.64 L 8.55 10 a 4.05 4.05 0 0 0 -0.42 1 H 5.41 L 3.71 9.29 a 1 1 0 0 0 -1.42 1.42 L 3.59 12 l -1.3 1.29 a 1 1 0 0 0 0 1.42 a 1 1 0 0 0 1.42 0 L 5.41 13 H 8.13 a 4.05 4.05 0 0 0 0.42 1 L 6.64 16 H 4.22 a 1 1 0 1 0 0 2 H 6.05 v 1.83 a 1 1 0 0 0 2 0 V 17.36 L 10 15.45 a 4.05 4.05 0 0 0 1 0.42 v 2.72 l -1.71 1.7 a 1 1 0 0 0 1.42 1.42 L 12 20.41 l 1.29 1.3 a 1 1 0 0 0 1.42 0 a 1 1 0 0 0 0 -1.42 L 13 18.59 V 15.87 a 4.05 4.05 0 0 0 1 -0.42 L 16 17.36 v 2.42 a 1 1 0 0 0 2 0 V 18 h 1.83 a 1 1 0 0 0 0 -2 H 17.36 L 15.45 14 a 4.05 4.05 0 0 0 0.42 -1 h 2.72 l 1.7 1.71 a 1 1 0 0 0 1.42 0 a 1 1 0 0 0 0 -1.42 Z m -7 1.39 l 0 0 l 0 0 a 2.13 2.13 0 0 1 -2.78 0 l 0 0 l 0 0 a 2 2 0 0 1 0 -2.78 l 0 0 l 0 0 a 2.13 2.13 0 0 1 2.78 0 l 0 0 l 0 0 a 2 2 0 0 1 0 2.78 Z"/></svg>')`,
          },
        },
        '& .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: '#aab4be',
          borderRadius: 20 / 2,
        },
      }));

      const handleChange = (event) => {
            localStorage.setItem('TimeChange', event.target.checked);
            dispatch(updateTimeChange(event.target.checked));
      };

    function handleLogout() {
        dispatch(deleteUser());
        history.go(0);
        location.reload();
    }
    return (
        <React.Fragment>
            <Navbar
                            id="AbstractHeader"
                collapseOnSelect
                expand="md"
                variant="dark"
                style={{
                    backgroundImage: `${config.linearGradientColor}`,
                    border: "0",
                    padding: "0.75rem",
                    borderRadius: "0",
                }}
            >
                <Navbar.Brand
                    href=""
                    onClick={() => history.replace("/")}
                    style={{ color: "#fff" ,cursor:"pointer"}}
                >
                    <img
                        id="Home"
                        src={logo}
                        className="d-inline-block align-top"
                        height="30"
                        alt="CA"
                    />{" "}
                    {config.name}
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mr-auto">
                        {props.navigations.map((x) => {
                            if (x.length === 1) {
                                return (
                                    <Nav.Link
                                        href=""
                                        onClick={() => history.replace(`${x[0].path}`)}
                                    >
                                        {x[0].title}
                                    </Nav.Link>
                                );
                            } else if (x.length>=1) {
                                return (
                                    <NavDropdown title={`${x[0].title}`} id="basic-nav-dropdown">
                                        {x[1].map((y) => {
                                            return (
                                                <NavDropdown.Item
                                                    href=""
                                                    onClick={() =>{ history.replace(`${y.path}`)}}
                                                >
                                                    {y.title}
                                                </NavDropdown.Item>
                                            );
                                        })}
                                    </NavDropdown>
                                );
                            }
                        })}
                    </Nav>
                </Navbar.Collapse>
                <Nav
                    style={{
                        padding: "0px 1.5rem",
                        marginRight: "3rem",
                        display: "-webkit-inline-box",
                    }}
                >
                    {props.toolbar.map((x) => {
                        return (
                            <Nav.Link
                                id={`${x.id}`}
                                style={{ color: `${x.color}` }}
                                onClick={() => dispatch(updateForm({...entities.get(x.id).FormData,state:true}))}>
                                {x.title}
                            </Nav.Link>
                        );
                    })}
        {<FormGroup>
                    <FormControlLabel
                        control={<MaterialUISwitch  onChange={handleChange} sx={{ m: 1 }} defaultChecked={localStorage.getItem('TimeChange')=='true'?true:false} />}
                    />
                    </FormGroup>}
                </Nav>
               
                {
                    props.exitIcon ?<Nav>
                    <Navbar.Text style={{ fontSize: "1.3rem" }}>
                        {
                            props.badge?<Badge
                            variant={user.permissions === "admin" ? "danger" : "success"}
                        >
                            {" "}
                            {user.username}{" "}
                        </Badge>:null
                        }
                        <GiExitDoor
                            onClick={handleLogout}
                            id="Logout"
                            style={{ fontSize: "1.2rem" ,cursor:"pointer"}}
                        />
                    </Navbar.Text>
                </Nav>
                :null
                }
                
            </Navbar>
        </React.Fragment>
    );
}
