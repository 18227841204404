let entities = new Map();
import axios from "axios";
import { API } from "../../const";
import { toast } from 'react-toastify';
import ClientGetter from './clientGetter'
import ExpenseCustomInput from './expenseCustomInput'
import VoucherGetter from './voucherGetter'
import { generateVoucher } from '../../services/voucher';
import lessonRadioGroup from "./lessonRadioGroup";
async function PostEntity({ data, api }) {
    await axios.post(API + api, { data }, {
        validateStatus: function (status) {
            return status <= 500;
        }
    }).then((res) => {
        if (res.status == 200) {
            toast.success(res.data)
            window.location.reload();
        } else {
            toast.error(res.data)
        }
    }).catch((err) => {
        console.error(err);
        toast.error(err.data);
    });
}
async function EditEntity({ data, api }) {
    await axios.put(API + api, { data }, {
        validateStatus: function (status) {
            return status <= 500;
        }
    }).then((res) => {
        if (res.status == 200) {
            toast.success(res.data)
            window.location.reload();
        } else {
            toast.error(res.data)
        }
    }).catch((err) => {
        console.error(err);
        toast.error(err.data);
    });
}
entities.set('client', {
    text: "client",
    FormData: {
        rows: [
            [{
                name: "client.name",
                label: "الأسم",
                type: "text",
                value: "",
                size: "small",
                helperText: "لا يترك فارغا",
                placeHolder: "الأسم",
                variant: "outlined",
                xs: 12,
                md: 12,
                registerObject: {
                    required: true,
                },
            }, {
                name: "client.mobilePhone",
                label: "رقم التليفون",
                type: "number",
                value: "",
                size: "small",
                helperText: "ادخل رقم نليفون صحيح",
                placeHolder: "01xxxxxxxxx",
                variant: "outlined",
                xs: 12,
                md: 12,
                registerObject: {
                    required: true,
                    minLength: 11
                },
            }, {
                name: "client.address",
                label: "عنوان",
                type: "text",
                value: "",
                size: "small",
                helperText: "لا يترك فارغا",
                placeHolder: "العنوان",
                variant: "outlined",
                xs: 12,
                md: 12,
            },
            {
                name: "user.username",
                label: "اسم المستخدم",
                type: "text",
                value: "",
                size: "small",
                helperText: "لا يترك فارغا",
                placeHolder: "اسم المستخدم",
                variant: "outlined",
                xs: 12,
                md: 6,
                registerObject: {
                    required: true,
                },
            }, {
                name: "user.password",
                label: "كلمة المرور",
                type: "password",
                value: "",
                size: "small",
                helperText: "لا يترك فارغا",
                placeHolder: "**********",
                variant: "outlined",
                xs: 12,
                md: 6,
                registerObject: {
                    required: true,
                },
            }, {
                name: "client.email",
                label: "البريد الإلكتروني",
                type: "text",
                value: "",
                size: "small",
                placeHolder: "",
                variant: "outlined",
                xs: 12,
                md: 12,
            }, {
                name: "client.nationalId",
                label: "الرقم القومي",
                type: "number",
                value: "",
                size: "small",
                placeHolder: "",
                variant: "outlined",
                xs: 12,
                md: 6,
            }, {
                name: "client.licenceNumber",
                label: "رقم الرخصة",
                type: "number",
                value: "",
                size: "small",
                placeHolder: "",
                variant: "outlined",
                xs: 12,
                md: 6,
            }, {
                name: "course.tech",
                label: "نوع الكورس",
                type: "select",
                value: "",
                size: "small",
                rows: [
                    { value: "مانيوال", label: "مانيوال" },
                    { value: "أوتوماتك", label: "أوتوماتك" },
                    { value: "ميكس", label: "ميكس" }
                ],
                placeHolder: "",
                variant: "outlined",
                xs: 12,
                md: 6,
            }, {
                name: "course.level",
                label: "مستوى",
                type: "select",
                value: "",
                size: "small",
                rows: [
                    { value: "مبتدأ", label: "مبتدأ" },
                    { value: "تكميلي", label: "تكميلي" },
                    { value: "ممارسة", label: "ممارسة" }
                ],
                placeHolder: "",
                variant: "outlined",
                xs: 12,
                md: 6,
            },
            {
                name: "course.minus",
                label: "سعر الكورس",
                type: "number",
                value: "",
                size: "small",
                helperText: "لا يترك فارغا",
                placeHolder: "",
                variant: "outlined",
                xs: 12,
                md: 12,
                registerObject: {
                    required: true,
                },
            }
            ]
        ],
        noSubmit: false,
        grid: { xs: 12, md: 12 },
        dir: "rtl",
        fullWidth: true,
        submitButtonText: "إضافة",
        editButtonText: "تعديل",
        submitType: 'insert',
        submitButtonFullWidth: true,
        editHandler: (data) => EditEntity({ data, api: "api/client/", text: 'client' }),
        submitHandler: (data) => PostEntity({ data, api: "api/client/", text: 'client' })
    }
})


entities.set('car', {
    text: "car",
    api: "car/",
    FormData: {
        rows: [
            [{
                name: "name",
                label: "الأسم",
                type: "text",
                value: "",
                size: "small",
                helperText: "لا يترك فارغا",
                placeHolder: "الأسم",
                variant: "outlined",
                xs: 12,
                md: 12,
                registerObject: {
                    required: true,
                  },
            }, {
                name: "ownerName",
                label: "اسم المالك",
                type: "text",
                value: "",
                size: "small",
                helperText: "لا يترك فارغا",
                placeHolder: "اسم المالك",
                variant: "outlined",
                xs: 12,
                md: 12,
            }, {
                name: "company",
                label: "الماركة",
                type: "text",
                value: "",
                size: "small",
                helperText: "لا يترك فارغا",
                placeHolder: "الماركة",
                variant: "outlined",
                xs: 12,
                md: 12,
            }, {
                name: "year",
                label: "سنة الصنع",
                type: "number",
                value: "",
                size: "small",
                helperText: "لا يترك فارغا",
                placeHolder: "سنة الصنع",
                variant: "outlined",
                xs: 12,
                md: 12,
            }, {
                name: "fuelType",
                label: "نوع الوقود",
                type: "number",
                value: "",
                size: "small",
                helperText: "لا يترك فارغا",
                placeHolder: "نوع الوقود",
                variant: "outlined",
                xs: 12,
                md: 12,
            }, {
                name: "licenceNumber",
                label: "رقم اللوحة",
                type: "text",
                value: "",
                size: "small",
                helperText: "لا يترك فارغا",
                placeHolder: "رقم اللوحة",
                variant: "outlined",
                xs: 12,
                md: 12,
            },
            {
                name: "motorNumber",
                label: "رقم الموتور",
                type: "text",
                value: "",
                size: "small",
                helperText: "لا يترك فارغا",
                placeHolder: "رقم الموتور",
                variant: "outlined",
                xs: 12,
                md: 12,
            },
            {
                name: "chassisNumber",
                label: "رقم الشاسية",
                type: "text",
                value: "",
                size: "small",
                helperText: "لا يترك فارغا",
                placeHolder: "رقم الشاسية",
                variant: "outlined",
                xs: 12,
                md: 12,
            }, {
                name: "color",
                label: "اللون",
                type: "color",
                value: "",
                size: "small",
                helperText: "لا يترك فارغا",
                placeHolder: "",
                variant: "outlined",
                xs: 12,
                md: 12,
            }
                , {
                name: "taxEnd",
                label: "نهاية الضريبة",
                type: "date",
                value: "",
                size: "small",
                helperText: "لا يترك فارغا",
                placeHolder: "نهاية الضريبة",
                variant: "outlined",
                xs: 12,
                md: 12,
            }, {
                name: "licenceDate",
                label: "تاريخ اصدار الرخصة",
                type: "date",
                value: "",
                size: "small",
                helperText: "لا يترك فارغا",
                placeHolder: "تاريخ اصدار الرخصة",
                variant: "outlined",
                xs: 12,
                md: 12,
            }, {
                name: "tech",
                label: "التقنية",
                type: "select",
                value: "",
                size: "small",
                rows: [
                    { value: "مانيوال", label: "مانيوال" },
                    { value: "أوتوماتيك", label: "أوتوماتيك" }
                ],
                helperText: "لا يترك فارغا",
                placeHolder: "",
                variant: "outlined",
                xs: 12,
                md: 6,
            }, {
                name: "type",
                label: "نوع",
                type: "select",
                value: "",
                size: "small",
                rows: [
                    { value: "simulator", label: "simulator" },
                    { value: "car", label: "car" }
                ],
                helperText: "لا يترك فارغا",
                placeHolder: "",
                variant: "outlined",
                xs: 12,
                md: 6,
            }, {
                name: "branchId",
                label: "الفرع",
                type: "select",
                value: "",
                size: "small",
                rows: [
                    { value: "1", label: "الاسكندرية" },
                    { value: "2", label: "دمنهور" },
                    { value: "3", label: "طنطا" },
                ],
                helperText: "لا يترك فارغا",
                placeHolder: "",
                variant: "outlined",
                xs: 12,
                md: 6,
            },{
                name: "active",
                label: "سيارة متاحة ؟",
                type: "select",
                value: "",
                size: "small",
                rows: [
                    { value: true, label: "متاح" },
                    { value: false, label: "غير متاح" }
                ],
                helperText: "لا يترك فارغا",
                placeHolder: "",
                variant: "outlined",
                xs: 12,
                md: 6,
            }

            ]
        ],
        noSubmit: false,
        grid: { xs: 12, md: 12 },
        dir: "rtl",
        fullWidth: true,
        submitButtonText: "إضافة",
        editButtonText: "تعديل",
        submitType: 'insert',
        submitButtonFullWidth: true,
        editHandler: (data) => EditEntity({ data, api: "api/car/", text: 'car' }),
        submitHandler: (data) => PostEntity({ data, api: "api/car/", text: 'car' })
    }
})

entities.set('captain', {
    text: "captain",
    FormData: {
        rows: [
            [{
                name: "name",
                label: "الأسم",
                type: "text",
                value: "",
                size: "small",
                helperText: "لا يترك فارغا",
                placeHolder: "الأسم",
                variant: "outlined",
                xs: 12,
                md: 12,
                registerObject: {
                    required: true,
                  },
            }, {
                name: "mobilePhone",
                label: "رقم التليفون",
                type: "number",
                value: "",
                size: "small",
                helperText: "لا يترك فارغا",
                placeHolder: "01xxxxxxxxx",
                variant: "outlined",
                xs: 12,
                md: 12,
                registerObject: {
                    required: true,
                    minLength:11
                  },
            }, {
                name: "address",
                label: "عنوان",
                type: "text",
                value: "",
                size: "small",
                helperText: "لا يترك فارغا",
                placeHolder: "العنوان",
                variant: "outlined",
                xs: 12,
                md: 12,
            }, {
                name: "nationalId",
                label: "الرقم القومي",
                type: "number",
                value: "",
                size: "small",
                helperText: "لا يترك فارغا",
                placeHolder: "",
                variant: "outlined",
                xs: 12,
                md: 6,
            }, {
                name: "profession",
                label: "المهنه",
                type: "text",
                value: "",
                size: "small",
                helperText: "لا يترك فارغا",
                placeHolder: "",
                variant: "outlined",
                xs: 12,
                md: 6,
            }, {
                name: "licenceType",
                label: "نوع الرخصه",
                type: "text",
                value: "",
                size: "small",
                helperText: "لا يترك فارغا",
                placeHolder: "",
                variant: "outlined",
                xs: 12,
                md: 6,
            }, {
                name: "licenceNumber",
                label: "رقم الرخصة",
                type: "number",
                value: "",
                size: "small",
                helperText: "لا يترك فارغا",
                placeHolder: "",
                variant: "outlined",
                xs: 12,
                md: 6,
            }
                , {
                name: "licenceStartDate",
                label: "تاريخ بداية الرخصة",
                type: "date",
                value: "",
                size: "small",
                helperText: "لا يترك فارغا",
                placeHolder: "",
                variant: "outlined",
                xs: 12,
                md: 6,
            }, {
                name: "licenceEndDate",
                label: "تاريخ نهاية الرخصة",
                type: "date",
                value: "",
                size: "small",
                helperText: "لا يترك فارغا",
                placeHolder: "تاريخ اصدار الرخصة",
                variant: "outlined",
                xs: 12,
                md: 6,
            }, {
                name: "color",
                label: "اللون",
                type: "color",
                value: "",
                size: "small",
                helperText: "لا يترك فارغا",
                placeHolder: "",
                variant: "outlined",
                xs: 12,
                md: 6,
            }, {
                name: "gender",
                label: "النوع",
                type: "select",
                value: "",
                size: "small",
                rows: [
                    { value: "male", label: "ذكر" },
                    { value: "female", label: "انثى" }
                ],
                helperText: "لا يترك فارغا",
                placeHolder: "",
                variant: "outlined",
                xs: 12,
                md: 6,
            }, {
                name: "branchId",
                label: "الفرع",
                type: "select",
                value: "",
                size: "small",
                rows: [
                    { value: "1", label: "الاسكندرية" },
                    { value: "2", label: "دمنهور" },
                    { value: "3", label: "طنطا" },

                ],
                helperText: "لا يترك فارغا",
                placeHolder: "",
                variant: "outlined",
                xs: 12,
                md: 12,
            },{
                name: "active",
                label: "كابتن متاح ؟",
                type: "select",
                value: "",
                size: "small",
                rows: [
                    { value: true, label: "متاح" },
                    { value: false, label: "غير متاح" }
                ],
                helperText: "لا يترك فارغا",
                placeHolder: "",
                variant: "outlined",
                xs: 12,
                md: 12,
            }

            ]
        ],
        noSubmit: false,
        grid: { xs: 12, md: 12 },
        dir: "rtl",
        fullWidth: true,


        submitButtonText: "إضافة",
        editButtonText: "تعديل",
        submitType: 'insert',
        submitButtonFullWidth: true,
        submitHandler: (data) => PostEntity({ data, api: "api/captain/", text: 'captain' }),
        editHandler: (data) => EditEntity({ data, api: "api/captain/", text: 'captain' })
    }
})

entities.set('user', {
    FormData: {
        rows: [
            [{
                name: "username",
                label: "اسم المستخدم",
                type: "text",
                value: "",
                size: "small",
                helperText: "لا يترك فارغا",
                placeHolder: "اسم المستخدم",
                variant: "outlined",
                xs: 12,
                md: 12,
                registerObject: {
                    required: true,
                  },
            }, {
                name: "password",
                label: "كلمة المرور",
                type: "password",
                value: "",
                size: "small",
                helperText: "لا يترك فارغا",
                placeHolder: "",
                registerObject: {
                    required: true,
                  },
            }, {
                name: "permissions",
                label: "نوع المستخدم",
                type: "select",
                value: "",
                size: "small",
                rows: [
                    { value: "admin", label: "admin" },
                    { value: "secretary", label: "secretary" }
                ],
                helperText: "لا يترك فارغا",
                placeHolder: "",
                variant: "outlined",
                xs: 12,
                md: 12,
            },{
                name: "branchId",
                label: "الفرع",
                type: "select",
                value: "",
                size: "small",
                rows: [
                    { value: null, label: "جميع الأفرع" },
                    { value: "1", label: "الاسكندرية" },
                    { value: "2", label: "دمنهور" },
                    { value: "3", label: "طنطا" },

                ],
                helperText: "لا يترك فارغا",
                placeHolder: "",
                variant: "outlined",
                xs: 12,
                md: 12,
            },{
                name: "active",
                label: "موظف متاح ؟",
                type: "select",
                value: "",
                size: "small",
                rows: [
                    { value: true, label: "متاح" },
                    { value: false, label: "غير متاح" }
                ],
                helperText: "لا يترك فارغا",
                placeHolder: "",
                variant: "outlined",
                xs: 12,
                md: 12,
            }


            ]
        ],
        noSubmit: false,
        grid: { xs: 12, md: 12 },
        dir: "rtl",
        fullWidth: true,

        submitButtonText: "إضافة",
        editButtonText: "تعديل",
        submitType: 'insert',
        submitButtonFullWidth: true,
        submitHandler: (data) => PostEntity({ data, api: "api/user/employee", text: 'user' }),
        editHandler: (data) => EditEntity({ data, api: "api/user/employee", text: 'uer' })
    }
})

entities.set('receipt', {
    FormData: {
        rows: [
            [{
                name: "clientId",
                label: "رقم العميل",
                type: 'custom',
                component: ClientGetter,
                xs: 12,
                md: 12,
            }, {
                name: "amount",
                label: "المبلغ",
                type: "number",
                value: "",
                size: "small",
                helperText: "لا يترك فارغا",
                placeHolder: "",
                variant: "outlined",
                xs: 12,
                md: 12,
                registerObject: {
                    required: true,
                  },
            }, {
                name: "branchId",
                label: "الفرع",
                type: "select",
                value: "",
                size: "small",
                rows: [
                    { value: "1", label: "الاسكندرية" },
                    { value: "2", label: "دمنهور" },
                    { value: "3", label: "طنطا" },
                ],
                helperText: "لا يترك فارغا",
                placeHolder: "",
                variant: "outlined",
                xs: 12,
                md: 12,
            }
                , {
                name: "notes",
                label: "ملاحظات",
                type: "textarea",
                value: "",
                size: "small",
                helperText: "لا يترك فارغا",
                placeHolder: "",
                variant: "outlined",
                xs: 12,
                md: 12,
            }

            ]
        ],
        noSubmit: false,
        grid: { xs: 12, md: 12 },
        dir: "rtl",
        fullWidth: true,
        submitButtonText: "إضافة",
        editButtonText: "تعديل",
        submitType: 'insert',
        submitButtonFullWidth: true,
        submitHandler: (data) => PostEntity({ data, api: "api/receipt", text: 'receipt' }),
        editHandler: (data) => EditEntity({ data, api: "api/receipt", text: 'receipt' })

    }
})
entities.set('voucher', {
    FormData: {
        rows: [
            [{
                name: "clientType",
                label: "نوع العميل",
                type: "select",
                value: "",
                size: "small",
                rows: [
                    { value: "client", label: "عميل حالي" },
                    { value: "guest", label: "عميل خارجي" },
                    { value: "Gifts", label: "gifts" }
                ],
                helperText: "لا يترك فارغا",
                placeHolder: "",
                variant: "outlined",
                xs: 12,
                md: 12,
            }, {
                name: "maxTries",
                label: "عدد المحاولات",
                type: "number",
                value: "",
                size: "small",
                helperText: "لا يترك فارغا",
                placeHolder: "",
                variant: "outlined",
                xs: 12,
                md: 12,
            }, {
                name: "clientId",
                label: "رقم العميل",
                type: 'custom',
                component: VoucherGetter,
                xs: 12,
                md: 12,
            }
            ]
        ],
        noSubmit: false,
        grid: { xs: 12, md: 12 },
        dir: "rtl",
        fullWidth: true,
        submitHandler: generateVoucher,
        submitButtonText: "إضافة",
        editButtonText: "تعديل",
        submitType: 'insert',
        submitButtonFullWidth: true,
        editHandler: null,

    }
})
entities.set('expense', {
    FormData: {
        rows: [
            [{
                name: "type",
                label: "النوع",
                type: 'custom',
                component: ExpenseCustomInput,
                xs: 12,
                md: 12,
            }, {
                name: "amount",
                label: "المبلغ",
                type: "number",
                value: "",
                size: "small",
                helperText: "لا يترك فارغا",
                placeHolder: "",
                variant: "outlined",
                xs: 12,
                md: 12,
                registerObject: {
                    required: true,
                  },
            }, {
                name: "branchId",
                label: "الفرع",
                type: "select",
                value: "",
                size: "small",
                rows: [
                    { value: "1", label: "الاسكندرية" },
                    { value: "2", label: "دمنهور" },
                    { value: "3", label: "طنطا" },
                ],
                helperText: "لا يترك فارغا",
                placeHolder: "",
                variant: "outlined",
                xs: 12,
                md: 12,
            }
                , {
                name: "notes",
                label: "ملاحظات",
                type: "textarea",
                value: "",
                size: "small",
                helperText: "لا يترك فارغا",
                placeHolder: "",
                variant: "outlined",
                xs: 12,
                md: 12,
            }

            ]
        ],
        noSubmit: false,
        grid: { xs: 12, md: 12 },
        dir: "rtl",
        fullWidth: true,
        submitButtonText: "إضافة",
        editButtonText: "تعديل",
        submitType: 'insert',
        submitButtonFullWidth: true,
        submitHandler: (data) => PostEntity({ data, api: "api/expenses", text: 'expense' }),
        editHandler: (data) => EditEntity({ data, api: "api/expenses", text: 'expense' }),
    }
})
entities.set('lesson', {
    FormData: {
        rows: [
            [
                {
                    name: "lessonId",
                    label: "",
                    type: "number",
                    hidden: true,
                    value: "",
                    size: "small",
                    helperText: "لا يترك فارغا",
                    placeHolder: "",
                    variant: "outlined",
                    xs: 0,
                    md: 0,
                },
                {
                    name: "car.id",
                    label: "رقم السيارة",
                    type: "number",
                    hidden: true,
                    value: "",
                    size: "small",
                    helperText: "لا يترك فارغا",
                    placeHolder: "",
                    variant: "outlined",
                    xs: 0,
                    md: 0,
                }, {
                    name: "car.name",
                    label: "اسم لسيارة",
                    disabled: true,
                    type: "text",
                    value: "",
                    size: "small",
                    helperText: "لا يترك فارغا",
                    placeHolder: "",
                    variant: "outlined",
                    xs: 12,
                    md: 12,
                }, {
                    label: "رقم العميل",
                    type: 'custom',
                    component: ClientGetter,
                    xs: 12,
                    md: 12,
                    registerObject: {
                        required: true,
                      },
                }, {
                    name: "captainId",
                    label: "اسم الكابتن",
                    type: "select",
                    value: "",
                    size: "small",
                    rows: JSON.parse(localStorage.getItem('captains')),
                    helperText: "لا يترك فارغا",
                    placeHolder: "",
                    variant: "outlined",
                    xs: 12,
                    md: 12,
                    registerObject: {
                        required: true,
                      },
                }, {
                    name: "date",
                    label: "تاريخ",
                    disabled: true,
                    type: "date",
                    value: "",
                    size: "small",
                    helperText: "لا يترك فارغا",
                    placeHolder: "",
                    variant: "outlined",
                    xs: 12,
                    md: 12,
                }, {
                    name: "from",
                    disabled: true,
                    label: "من",
                    type: "time",
                    value: "",
                    size: "small",
                    helperText: "لا يترك فارغا",
                    placeHolder: "",
                    variant: "outlined",
                    xs: 6,
                    md: 6,
                }, {
                    name: "to",
                    label: "إلى",
                    disabled: true,
                    type: "time",
                    value: "",
                    size: "small",
                    helperText: "لا يترك فارغا",
                    placeHolder: "",
                    variant: "outlined",
                    xs: 6,
                    md: 6,
                }, {
                    name: "branchId",
                    label: "الفرع",
                    type: "select",
                    value: "",
                    size: "small",
                    rows: [
                        { value: "1", label: "الاسكندرية" },
                        { value: "2", label: "دمنهور" },
                        { value: "3", label: "طنطا" },
                    ],
                    helperText: "لا يترك فارغا",
                    placeHolder: "",
                    variant: "outlined",
                    xs: 12,
                    md: 12,
                }
                , {
                    name: "notes",
                    label: "ملاحظات",
                    type: "textarea",
                    value: "",
                    size: "small",
                    helperText: "لا يترك فارغا",
                    placeHolder: "",
                    variant: "outlined",
                    xs: 12,
                    md: 12,
                }, {
                    name: "type",
                    label: "حصة إضافية ؟",
                    type: "custom",
                    component: lessonRadioGroup,
                    xs: 12,
                    md: 12,
                    registerObject: {
                        required: true,
                      },
                }

            ]
        ],
        noSubmit: false,
        grid: { xs: 12, md: 12 },
        dir: "rtl",
        fullWidth: true,
        submitButtonText: "إضافة",
        editButtonText: "تعديل",
        submitType: 'insert',
        deleteButton:true,
        deleteHandler:async (id)=>{
            await axios.post(API+"api/lesson/delete",{id}).then(()=>{
                toast.success('تم المسح بنجاح')
                window.location.reload();
            }).catch(()=>{
                toast.error('لايمكن المسح تواصل مع المدير')
            })
        },
        submitButtonFullWidth: true,
        editHandler: (data) => EditEntity({ data, api: "api/lesson", text: 'lesson' }),
        submitHandler: (data) => PostEntity({ data, api: "api/lesson", text: 'lesson' })
    }
})


entities.set('changepassword', {
    FormData: {
        rows: [
            [{
                name: "passwordOld",
                label: "كلمة المرور الحالية",
                type: "password",
                value: "",
                size: "small",
                helperText: "لا يترك فارغا",
                placeHolder: "",
                variant: "outlined",
                xs: 12,
                md: 12,
            },
                {
                name: "passwordNew",
                label: "كلمة المرور الجديدة",
                type: "password",
                value: "",
                size: "small",
                helperText: "لا يترك فارغا",
                placeHolder: "",
                variant: "outlined",
                xs: 12,
                md: 12,
            }]
        ],
        noSubmit: false,
        grid: { xs: 12, md: 12 },
        dir: "rtl",
        fullWidth: true,
        submitButtonText: "تغيير كلمة المرور",
        editButtonText: "تعديل",
        submitType: 'insert',
        submitButtonFullWidth: true,
        submitHandler: (data) => PostEntity({ data, api: "api/user/changePassword", text: '' }),
        editHandler: (data) => EditEntity({ data, api: "api/user/changePassword", text: '' })

    }
})

entities.set('reports', {
    FormData: {
        rows: [
            [{
                name: "from",
                label: "مــن",
                type: "date",
                value: "",
                size: "small",
                helperText: "لا يترك فارغا",
                placeHolder: "اسم المستخدم",
                variant: "outlined",
                xs: 12,
                md: 12,
                registerObject: {
                    required: true,
                  },
            }, {
                name: "to",
                label: "إلــى",
                type: "date",
                value: "",
                size: "small",
                helperText: "لا يترك فارغا",
                placeHolder: "",
                registerObject: {
                    required: true,
                  },
            },{
                name: "branchId",
                label: "الفرع",
                type: "select",
                value: "",
                size: "small",
                rows: [
                    { value: null, label: "جميع الأفرع" },
                    { value: "1", label: "الاسكندرية" },
                    { value: "2", label: "دمنهور" },
                    { value: "3", label: "طنطا" },

                ],
                helperText: "لا يترك فارغا",
                placeHolder: "",
                variant: "outlined",
                xs: 12,
                md: 12,
            }

            ]
        ],
        noSubmit: false,
        grid: { xs: 12, md: 12 },
        dir: "rtl",
        fullWidth: true,
        disableCloseButton:true,
        submitButtonText: "عــرض",
        editButtonText: "تعديل",
        submitType: 'insert',
        submitButtonFullWidth: true,
    }
})

export default entities;