import React  ,{ useState, Suspense, lazy }from 'react';
import ReactDOM from 'react-dom';
const App = lazy(()=> import('./App'));
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.css';
import { Provider } from 'react-redux';
import store from './store.js';
import ErrorBoundary from './ErrorBounderies';
import ErrorScreen from './ErrorBounderies/ErrorScreen';
import LoadingPage from './components/Loading/LoadingPage.js';

(function () {
  var token = window.localStorage.getItem('token');
  if (token) {
    axios.defaults.headers.common['x-auth-token'] = token;
  }
})();

ReactDOM.render(
    <Provider store={store}>
        <ErrorBoundary Fallback={ErrorScreen}>
          <Suspense fallback={<LoadingPage />}>
            <App />
          </Suspense>
        </ErrorBoundary>
    </Provider>
  ,
  document.getElementById('root')
);
