import React from "react";
import AbstractHeader from "./AbstractHeader";
import { FaHome,FaReceipt,FaUserPlus} from 'react-icons/fa';
import {  GiTicket } from "react-icons/gi";
import { useSelector } from "react-redux";
import config from '../../config'

function Header() {
  const permissions = useSelector((state) => state.user.permissions);
  return (
    permissions === "admin" || permissions === "secretary" ?
    <AbstractHeader
      badge = {true}
      exitIcon= {true}
      toolbar={[
        { title: <FaUserPlus/> ,id:"client",tooltip:"عميل",color:"white"},
        { title: <FaReceipt/> ,id:"receipt",tooltip:"إيصال",color:"#58e879"},
        { title: <GiTicket/> ,id:"voucher",tooltip:"كود",color:"#ffc107"},
        { title: <FaReceipt/> ,id:"expense",tooltip:"مصروف",color:"#e65257"}
      ]}
      navigations={[
        [{ title: <FaHome/>, path: "/Home" }],
        [
          { title: "الإعدادات" },
          [
            { title: "العملاء", path: "/list/client" },
            { title: "السيارات", path: "/list/car" },
            { title: "الكباتن", path: "/list/captain" },
            ...(permissions==="admin"?[
              { title: "المستخدمين", path: "/list/user" },
              { title: "التقارير", path: "/reports" },
              {title:"حصص تم حذفها",path:"/list/deletedLesson"}
            ]:[])
          ],
        ],[
          { title: "العملاء", path: "/list/client" },
        ],
        [
          { title: "اختبار الإشارات" },
          [
            { title: "اختبار الإشارات", path: "/LoginApp" },
            ...(permissions==="admin"?[
              { title: "جميع الأكواد", path: "/list/voucher" },
              { title: "إدخال اسئله", path: "/QuestionsEntery" },
              { title: "جميع الأسئله", path: "Categories" },
            ]:[])
          ],
        ],
        ...(config.access==="SuperAccess"?[
          [{ title: "حجز اونلاين", path: "/Bookings" }],
        ]:[]),
      ]}
    />: 
    <AbstractHeader
    badge = {false}
    toolbar={[]}
    navigations={[
      ...(permissions==="Guest"?[]:[{ title: "تسجيل الدخول", path: "/login" }]),
      [{ title: "اختبار الإشارات", path: "/LoginApp" }],
      ...(config.access==="SuperAccess"?[
        [{ title: "حجز اونلاين", path: "/Bookings" }],
        [{ title: "حجوزاتي" ,path: "/UserBookings" }],
      ]:[])
    ]}
    exitIcon={permissions==="Guest"?true:false}
  />
  )
}
export default Header;
