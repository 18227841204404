import { combineReducers } from 'redux';
import user from './user';
import AvailableLesson from './AvailableLesson'
import cart from './cart'
import voucher from './voucher';
import form from './form'
import captains from './captains';
import TimeChange from './timeChange'
export default combineReducers({
  user,
  AvailableLesson,
  cart,
  voucher,
  form,
  captains,
  TimeChange
});
