import axios from "axios";
import {API} from '../const.js'
import { toast } from 'react-toastify';
export async function generateVoucher(data) {
  let res = await axios.post(API+"api/voucher/generate",data).then(res=>{
    console.log(res)
    if (res.status == 200) {
      toast.success(res.data)
  } 
  })

  return res.data;
}
export async function getVoucherById(data) {
  let res = await axios.post(API+"api/voucher/getVoucherById",data);
  return res.data;
}
export async function loginVoucher(data){
  let res = await axios.post(API+"api/voucher/loginVoucher",data);
  if(res.data.token){
    localStorage.setItem('tokenApp', res.data.token);
  }else{
    alert(res.data);
  }
}
export async function fetchAll(){
  return await axios.get(API+'api/voucher/Vouchers');
}
export async function deleteVoucher(id){
   await axios.post(API+"api/voucher/deleteVoucher",{id});
}
export async function updateScore(data){
  return await axios.post(API+"api/voucher/updateScore",data);
}