import React,{useState} from 'react';
import { Grid, Typography } from '@mui/material';
import { Controller } from "react-hook-form";
import { TextField } from "@material-ui/core";
import { API } from '../../const';
import axios from 'axios';
import {getVoucherById} from '../../services/voucher';

export default function VoucherGetter(props) {
    const [client,setClient] = useState({
        id:0,
        voucher:''
    });
    async function fetchClientVoucher(id){
        try {
            let res = await getVoucherById({id:id,clientType:'client'});
            setClient({
                ...client,voucher:res
            })
        } catch (error) {
            console.log(error)
            setClient({
                ...client,voucher:''
            })
        }
    }
    return (
        <Grid container direction='row' spacing={2}>
            <Grid item xs={6} >
                <p style={{magin:'0px',color:'#17a2b8'}} variant='body1'>رقم العميل</p>
                <Controller
                    control={props.control}
                    rules={props.registerObject}
                    name={"id"}
                    render={({ field }) => (
                        <>
                            <TextField
                                {...field}
                                onChange={async (e)=> {
                                    field.onChange(e.target.value);
                                    await fetchClientVoucher(e.target.value);
                                }}
                                placeholder="رقم العميل"
                                color="primary"
                                type="number"
                                variant="outlined"
                                size= "small"
                                id={'variant' + "-basic"}
                                isRTL={true}
                            >

                            </TextField>
                        </>
                    )} />
            </Grid>
            <Grid item xs={6}>
            <p style={{magin:'0px',color:'#17a2b8'}} variant='body1'>كود</p>
                <Controller
                    disabled={true}
                    control={props.control}
                    rules={props.registerObject}
                    name={"voucher"}
                    render={({ field }) => (
                        <>
                            <TextField
                                {...field}
                                placeholder="كود"
                                color="primary"
                                type="text"
                                variant="outlined"
                                size= "small"
                                id={'variant' + "-basic"}
                                isRTL={true}
                                value={client.voucher}
                            >
                            </TextField>
                        </>
                    )} />
            </Grid>
        </Grid>
    )
}