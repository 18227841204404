const cart=(state={lesson:"",branch:""},action)=>{
    switch (action.type){
        case 'updateCart':
            console.log(action.payload)
            let newState = {
                lesson :action.payload.lesson,
                branch:action.payload.branch
            }
            return newState;
        case 'emptyCart':
            return {lesson:"",branch:""}
        default:
            return state;
    }
}
export default cart;