import React,{useState} from 'react'
import { Grid, Typography } from '@mui/material';
import { Controller } from "react-hook-form";
import { TextField } from "@material-ui/core";
import { API } from '../../const';
import axios from 'axios';
import MySelect from './FormBuilder/inputs/MySelect'
export default function ExpenseCustomInput(props){
    const [state,setState]=useState({
        carId:null,
        captainId:null,
        carName:'',
        captainName:''
    })    
    const [type,setType]= useState('captainRelated')
    const setExpenseType = (type) => {setType(type)}

    async function fetchCarName(id){
        try {
            let res = await axios.post(API+"api/"+"car"+"/carName",{id:id})
            setState({
                ...state,name:res.data.name
            })
            props.setValue("car.name", res.data.name)
        } catch (error) {
            console.log(error)
            setClient({
                ...state,name:''
            })
        }
    }
    async function fetchCaptainName(id){
        try {
            let res = await axios.post(API+"api/"+"captain"+"/captainName",{id:id})
            setState({
                ...state,name:res.data.name
            })
            props.setValue("captain.name", res.data.name)
        } catch (error) {
            console.log(error)
            setClient({
                ...state,name:''
            })
        }
    }
    return(
        <Grid container direction='row' spacing={2}>
            <Grid item xs={12}>
                <MySelect {...props} customOnChage={setExpenseType} {...{rows:[
                    { value: "captainRelated", label: "مصاريف خاصة بكابتن" },
                    { value: "carRelated", label: "مصاريف خاصة بسيارة" },
                    { value: "other", label: "مصاريف اخرى" }
                ]}} ></MySelect>
            </Grid>
            {
                type == 'carRelated' &&
                <>
                <Grid item xs={6} >
                <p style={{magin:'0px',color:'#17a2b8'}} variant='body1'>رقم السيارة</p>
                <Controller
                    control={props.control}
                    rules={props.registerObject}
                    name={"car.id"}
                    render={({ field }) => (
                        <>
                            <TextField
                                {...field}
                                onChange={async (e)=> {
                                    field.onChange(e.target.value);
                                    await fetchCarName(e.target.value);
                                }}
                                placeholder="رقم السيارة"
                                color="primary"
                                type="number"
                                variant="outlined"
                                size= "small"
                                id={'variant' + "-basic"}
                                isRTL={true}
                            >

                            </TextField>
                        </>
                    )} />
            </Grid>
                <Grid item xs={6}>
            <p style={{magin:'0px',color:'#17a2b8'}} variant='body1'>اسم السيارة</p>

                <Controller
                    disabled={true}
                    control={props.control}
                    rules={props.registerObject}
                    name={"car.name"}
                    render={({ field }) => (
                        <>
                            <TextField
                                {...field}
                                placeholder="اسم السيارة"
                                color="primary"
                                type="text"
                                variant="outlined"
                                size= "small"
                                id={'variant' + "-basic"}
                                isRTL={true}
                                value={state.carName|| props.getValues("car.name")}
                            >
                            </TextField>
                        </>
                    )} />
            </Grid>
                </>
            }
            {
                type == 'captainRelated' &&
                <>
   <Grid item xs={6} >
                <p style={{magin:'0px',color:'#17a2b8'}} variant='body1'>رقم الكابتن</p>
                <Controller
                    control={props.control}
                    rules={props.registerObject}
                    name={"captain.id"}
                    render={({ field }) => (
                        <>
                            <TextField
                                {...field}
                                onChange={async (e)=> {
                                    field.onChange(e.target.value);
                                    await fetchCaptainName(e.target.value);
                                }}
                                placeholder="رقم الكابتن"
                                color="primary"
                                type="number"
                                variant="outlined"
                                size= "small"
                                id={'variant' + "-basic"}
                                isRTL={true}
                            >

                            </TextField>
                        </>
                    )} />
            </Grid>
            <Grid item xs={6}>
            <p style={{magin:'0px',color:'#17a2b8'}} variant='body1'>اسم الكابتن</p>
                <Controller
                    disabled={true}
                    control={props.control}
                    rules={props.registerObject}
                    name={"captain.name"}
                    render={({ field }) => (
                        <>
                            <TextField
                                {...field}
                                placeholder="اسم الكابتن"
                                color="primary"
                                type="text"
                                variant="outlined"
                                size= "small"
                                id={'variant' + "-basic"}
                                isRTL={true}
                                value={state.captainName || props.getValues("captain.name")}
                            >
                            </TextField>
                        </>
                    )} />
            </Grid>
        
                </>
            }
         </Grid>
    )
}