import React, { useState, useEffect } from 'react';
import { FormLabel, Grid, Typography } from '@mui/material';
import { Controller } from "react-hook-form";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
export default function lessonRadioGroup(props) {
    const [value, setValue] = useState(null);
    const handleChange = (data)=>{
        props.setValue("type", data)
        setValue(data);

    }
    return (
        <Grid container direction='row' spacing={2}>
            <p style={{ magin: '0px', color: '#17a2b8' }} variant='body1'>حصة إضافية ؟</p>
            <Controller
                control={props.control}
                rules={props.registerObject}
                name={"type"}
                render={({ field }) => (
                    <>
                    <Grid container>
                        <Grid item>
                        <RadioGroup
                            {...field}
                            row
                            name="radio-buttons-group"
                            onChange={async (e)=> {
                                handleChange(e.target.value);
                                field.onChange(e.target.value);
                            }}
                            defaultValue={value || props.getValues("type")}

                        >
                            <FormControlLabel value={'extra'} control={<Radio />} label="نعم" />
                            <FormControlLabel value={'normal'} control={<Radio />} label="لا" />
                        </RadioGroup>
                        </Grid>
                    </Grid>

                    </>
                )
                } />
        </Grid >
    )
}